<template>
  <svg
    :width="width"
    :height="height"
    fill="none"
    viewBox="5 0 32 34"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect rx="0" fill="var(--default)"></rect>
    <path
      d="M20.5037 7.43127C17.9183 7.43127 15.3908 8.19796 13.2411 9.63438C11.0913 11.0708 9.41579 13.1124 8.42636 15.5011C7.43694 17.8898 7.17806 20.5182 7.68246 23.054C8.18687 25.5899 9.4319 27.9191 11.2601 29.7474C13.0883 31.5756 15.4176 32.8206 17.9534 33.325C20.4892 33.8294 23.1177 33.5705 25.5064 32.5811C27.895 31.5917 29.9367 29.9162 31.3731 27.7664C32.8095 25.6167 33.5762 23.0892 33.5762 20.5037C33.5762 17.0367 32.1989 13.7117 29.7474 11.2601C27.2958 8.80855 23.9708 7.43127 20.5037 7.43127ZM20.5037 31.5651C18.316 31.5651 16.1774 30.9163 14.3584 29.7009C12.5394 28.4855 11.1216 26.7579 10.2844 24.7367C9.44722 22.7155 9.22817 20.4915 9.65497 18.3458C10.0818 16.2001 11.1353 14.2292 12.6822 12.6822C14.2292 11.1353 16.2001 10.0818 18.3458 9.65496C20.4915 9.22816 22.7155 9.44721 24.7367 10.2844C26.7579 11.1216 28.4855 12.5394 29.7009 14.3584C30.9163 16.1774 31.5651 18.316 31.5651 20.5037C31.5651 23.4374 30.3997 26.2509 28.3253 28.3253C26.2509 30.3997 23.4374 31.5651 20.5037 31.5651Z"
      fill="white"
    ></path>
    <path
      d="M15.2653 20.2422C15.3587 20.3365 15.47 20.4113 15.5925 20.4624C15.715 20.5134 15.8465 20.5397 15.9792 20.5397C16.112 20.5397 16.2434 20.5134 16.3659 20.4624C16.4885 20.4113 16.5997 20.3365 16.6932 20.2422L19.0563 17.8792C19.5003 17.4288 19.7711 16.8361 19.8209 16.2055C19.8707 15.575 19.6963 14.9472 19.3284 14.4327C18.9606 13.9182 18.4229 13.5501 17.8102 13.3932C17.1975 13.2363 16.549 13.3008 15.9792 13.5753C15.4085 13.2839 14.7522 13.2071 14.1296 13.3588C13.507 13.5105 12.9596 13.8806 12.5869 14.4019C12.2143 14.9231 12.0411 15.5609 12.0989 16.199C12.1568 16.8372 12.4418 17.4334 12.9022 17.8792L15.2653 20.2422ZM14.32 15.4758C14.381 15.4119 14.4544 15.3611 14.5356 15.3264C14.6169 15.2916 14.7043 15.2737 14.7926 15.2737C14.881 15.2737 14.9684 15.2916 15.0497 15.3264C15.1309 15.3611 15.2042 15.4119 15.2653 15.4758C15.3587 15.5701 15.47 15.6449 15.5925 15.6959C15.715 15.747 15.8465 15.7733 15.9792 15.7733C16.112 15.7733 16.2434 15.747 16.3659 15.6959C16.4885 15.6449 16.5997 15.5701 16.6932 15.4758C16.7549 15.4132 16.8285 15.3635 16.9096 15.3295C16.9908 15.2956 17.0778 15.2781 17.1658 15.2781C17.2537 15.2781 17.3408 15.2956 17.422 15.3295C17.5031 15.3635 17.5767 15.4132 17.6384 15.4758C17.7023 15.5369 17.7531 15.6102 17.7879 15.6914C17.8226 15.7727 17.8405 15.8601 17.8405 15.9484C17.8405 16.0368 17.8226 16.1242 17.7879 16.2055C17.7531 16.2867 17.7023 16.36 17.6384 16.4211L15.9792 18.1104L14.32 16.4814C14.2561 16.4204 14.2053 16.347 14.1705 16.2658C14.1358 16.1846 14.1179 16.0971 14.1179 16.0088C14.1179 15.9204 14.1358 15.833 14.1705 15.7518C14.2053 15.6705 14.2561 15.5972 14.32 15.5362V15.4758Z"
      fill="white"
    ></path>
    <path
      d="M24.3155 20.2422C24.409 20.3365 24.5202 20.4113 24.6427 20.4624C24.7653 20.5134 24.8967 20.5397 25.0294 20.5397C25.1622 20.5397 25.2936 20.5134 25.4162 20.4624C25.5387 20.4113 25.6499 20.3365 25.7434 20.2422L28.1065 17.8792C28.5505 17.4288 28.8213 16.8361 28.8711 16.2055C28.9209 15.575 28.7465 14.9472 28.3786 14.4327C28.0108 13.9182 27.4731 13.5501 26.8604 13.3932C26.2477 13.2363 25.5993 13.3008 25.0294 13.5753C24.4588 13.2839 23.8024 13.2071 23.1798 13.3588C22.5573 13.5105 22.0098 13.8806 21.6372 14.4019C21.2645 14.9231 21.0913 15.5609 21.1492 16.199C21.207 16.8372 21.4921 17.4334 21.9524 17.8792L24.3155 20.2422ZM23.3703 15.4758C23.4313 15.4119 23.5046 15.3611 23.5859 15.3264C23.6671 15.2916 23.7545 15.2737 23.8429 15.2737C23.9312 15.2737 24.0187 15.2916 24.0999 15.3264C24.1811 15.3611 24.2545 15.4119 24.3155 15.4758C24.409 15.5701 24.5202 15.6449 24.6427 15.6959C24.7653 15.747 24.8967 15.7733 25.0294 15.7733C25.1622 15.7733 25.2936 15.747 25.4162 15.6959C25.5387 15.6449 25.6499 15.5701 25.7434 15.4758C25.8052 15.4132 25.8787 15.3635 25.9599 15.3295C26.041 15.2956 26.1281 15.2781 26.216 15.2781C26.304 15.2781 26.391 15.2956 26.4722 15.3295C26.5533 15.3635 26.6269 15.4132 26.6886 15.4758C26.7525 15.5369 26.8034 15.6102 26.8381 15.6914C26.8729 15.7727 26.8908 15.8601 26.8908 15.9484C26.8908 16.0368 26.8729 16.1242 26.8381 16.2055C26.8034 16.2867 26.7525 16.36 26.6886 16.4211L25.0294 18.1104L23.3703 16.4814C23.3064 16.4204 23.2555 16.347 23.2208 16.2658C23.186 16.1846 23.1681 16.0971 23.1681 16.0088C23.1681 15.9204 23.186 15.833 23.2208 15.7518C23.2555 15.6705 23.3064 15.5972 23.3703 15.5362V15.4758Z"
      fill="white"
    ></path>
    <path
      d="M27.5427 21.5093C27.276 21.5093 27.0202 21.6153 26.8317 21.8039C26.6431 21.9924 26.5371 22.2482 26.5371 22.5149C26.5371 25.2903 23.8321 27.5428 20.5037 27.5428C17.1752 27.5428 14.4703 25.2903 14.4703 22.5149C14.4703 22.2482 14.3643 21.9924 14.1757 21.8039C13.9871 21.6153 13.7314 21.5093 13.4647 21.5093C13.198 21.5093 12.9422 21.6153 12.7536 21.8039C12.5651 21.9924 12.4591 22.2482 12.4591 22.5149C12.4591 26.3964 16.0691 29.5539 20.5037 29.5539C24.9383 29.5539 28.5483 26.3964 28.5483 22.5149C28.5483 22.2482 28.4423 21.9924 28.2538 21.8039C28.0652 21.6153 27.8094 21.5093 27.5427 21.5093Z"
      fill="white"
    ></path>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AdplistIcon",
  inheritAttrs: true,
  props: {
    width: {
      type: Number,
      default: 28,
      required: false,
    },
    height: {
      type: Number,
      default: 28,
      required: false,
    },
  },
  computed: {
    style(): string {
      return `width: ${this.width}px; height: ${this.height}px;`;
    },
  },
});
</script>
