<template>
  <div class="job">
    <div class="company">
      <div class="logo-and-name">
        <div class="logo">
          <img
            v-if="job.company.logo"
            :src="require(`@/assets/${job.company.logo}`)"
            :alt="job.company.name"
          />

          <div v-else class="logo-placeholder">
            {{ "S" }}
          </div>
        </div>

        <div class="name">
          <div class="position">
            <h3>{{ job.position }}</h3>
          </div>

          <h4>
            {{ job.company.name }}

            <a
              v-if="job.company.website"
              class="link"
              :href="job.company.website"
              target="_blank"
            >
              <font-awesome-icon icon="external-link-alt" />
            </a>
          </h4>

          <div class="period">
            {{ fromDate }} - {{ toDate }} ({{ duration }})
          </div>

          <div class="location">
            {{ job.company.location }}
          </div>
        </div>
      </div>

      <div class="about">
        <span class="quote-icon">
          <font-awesome-icon icon="quote-left" />
        </span>

        {{ job.company.about }}

        <span class="quote-icon">
          <font-awesome-icon icon="quote-right" />
        </span>
      </div>
    </div>

    <div class="key-results">
      <h4>Highlights</h4>

      <ul>
        <li v-for="keyResult in job.keyResults" :key="keyResult">
          {{ keyResult }}
        </li>
      </ul>
    </div>

    <div class="tag-list">
      <TagList :tags="job.tags" />
    </div>
  </div>
</template>

<style lang="less" scoped>
ul {
  margin: 0.5em 0 1em;
  padding-left: 1em;

  li {
    margin: 0.5em 0;
  }
}

.job {
  margin-top: 1rem;
}

.company {
  margin-top: 1rem;

  .logo-and-name {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .logo {
    align-self: flex-start;
    width: 4rem;
    margin-top: 0.4rem;

    img {
      display: block;
      width: 100%;
      border-radius: 0.25rem;
    }
  }

  .logo-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    background-color: var(--green-background);
    border-radius: 0.25rem;
    font-size: 1.5rem;
    color: var(--white);
  }

  .name {
    margin-left: 0.5em;

    .link {
      margin-left: 0.25rem;
      font-size: 0.75em;
      color: var(--light-text-color);
      vertical-align: text-bottom;
    }
  }

  .period {
    margin-top: 0.25rem;
  }

  .period {
    font-size: 0.85rem;
    color: var(--text-color);
  }

  .location {
    font-size: 0.85rem;
    color: var(--light-text-color);
  }

  .about {
    margin: 1rem 0;
    color: var(--light-text-color);

    .quote-icon {
      font-size: 0.5em;
      vertical-align: text-top;
      opacity: 0.75;
    }
  }
}
</style>

<script lang="ts">
import TagList from "@/components/TagList.vue";
import { Job } from "@/types/Job";
import moment from "moment";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: {
    TagList,
  },
  computed: {
    fromDate(): string {
      return moment(this.job.period.from).format("MMM YYYY");
    },
    toDate(): string {
      if (this.job.current) {
        return "Current";
      }

      return moment(this.job.period.to).format("MMM YYYY");
    },
    duration(): string {
      let from = this.job.period.from;
      let to = this.job.period.to;

      if (!to) {
        to = new Date();
      }

      const years = moment.duration(moment(to).diff(moment(from))).years();
      const months = moment.duration(moment(to).diff(moment(from))).months();

      let duration = [];

      if (years == 1) {
        duration.push(`${years} year`);
      } else if (years > 0) {
        duration.push(`${years} years`);
      }

      if (months == 1) {
        duration.push(`${months} month`);
      } else if (months > 0) {
        duration.push(`${months} months`);
      }

      return duration.join(" ");
    },
  },
  props: {
    job: {
      type: Object as PropType<Job>,
      required: true,
    },
  },
});
</script>
